import React from "react";

import { useAuthClaimCheck, TENANT_PATH, CONTRACT_AND_INVOICES_PATH, MANAGE_SUBSCRIPTION_PATH } from "../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../components/profile/shared/PageContentContainer";
import ContractAndInvoicesContent from "../../components/profile/ContractAndInvoicesContent";

import { pushSAEvent } from "../../helpers";

export default ({ location }) => {
    const loading = useAuthClaimCheck(CONTRACT_AND_INVOICES_PATH);

    const menuItems = [
        {
            path: TENANT_PATH,
            text: 'My Frends',
        },
        // {
        //     path: CONTRACT_AND_INVOICES_PATH,
        //     text: 'Contracts and Invoices',
        //     open: true,
        // },
        {
            path: MANAGE_SUBSCRIPTION_PATH,
            text: 'Manage Your Subscription',
        },
    ];

    pushSAEvent('visit_profile_contract_and_invoices');

    return (
        <PageContentContainer
            title="Your profile | Contracts and Invoices"
            location={location}
            loading={loading}
            menuItems={menuItems}
            content={<ContractAndInvoicesContent />}
        />
    );
};