import React from "react";
import { Link } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { format } from 'date-fns';

import ContentContainer from "./shared/ContentContainer";
import ContentParagraph from "./shared/ContentParagraph";
import ContentTitle from "./shared/ContentTitle";
import ContentSubTitle from "./shared/ContentSubTitle";
import { TENANT_PATH } from "./shared/AuthClaimCheckHook";
import ContentButton from "./shared/ContentButton";

import { TENANT_API_BASE_URL } from "./TenantContent";

const ContractAndInvoicesContent = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [invoices, setInvoices] = React.useState();

    let invoicesController;
    const getInvoices = async () => {
        try {
            invoicesController = new AbortController();
            const token = await getAccessTokenSilently();
            const { data } = await axios.get(
                `${TENANT_API_BASE_URL}/invoices`,
                {
                    signal: invoicesController.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );
            setInvoices(data);
        } catch (e) { }
    };

    const openPdfToNewTab = (pdfString) => {
        if (window) {
            const pdfBlob = new Blob([pdfString], { type: 'application/pdf' });
            const pdfURL = URL.createObjectURL(pdfBlob);
            window.open(pdfURL, '_blank');
        }
    };

    let contractController;
    const handleContractPDFDownload = async () => {
        try {
            contractController = new AbortController();
            const token = await getAccessTokenSilently();
            const { data } = await axios.get(
                `${TENANT_API_BASE_URL}/contract`,
                {
                    signal: contractController.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );
            openPdfToNewTab(data);
        } catch (e) { }
    };

    let invoiceController;
    const handleInvoicePDFDownload = async (invoiceId) => {
        try {
            invoiceController = new AbortController();
            const token = await getAccessTokenSilently();
            const { data } = await axios.get(
                `${TENANT_API_BASE_URL}/invoices/${invoiceId}`,
                {
                    signal: invoiceController.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );
            openPdfToNewTab(data);
        } catch (e) { }
    };

    React.useEffect(() => {
        getInvoices();

        return () => {
            if (invoicesController) invoicesController.abort();
            if (contractController) contractController.abort();
            if (invoiceController) invoiceController.abort();
        }
    }, []);

    return (
        <ContentContainer loading={true}>
            {/* <ContentParagraph>
                <Link to={TENANT_PATH} className="is-purple is-underlined">
                    Back to tenant view
                </Link>
            </ContentParagraph>
            <ContentTitle>
                Contracts and Invoices
            </ContentTitle>
            <ContentButton onClick={handleContractPDFDownload}>
                See your contract
            </ContentButton>
            <ContentSubTitle>
                Invoice history
            </ContentSubTitle>
            <InvoicesTable
                invoices={invoices}
                onDownloadInvoiceButtonClick={handleInvoicePDFDownload}
            /> */}
        </ContentContainer>
    );
};

const InvoicesTable = ({
    invoices,
    onDownloadInvoiceButtonClick,
}) => (
    <>
        {invoices?.length ? (
            <div style={{ width: '100%', overflowX: 'auto' }}>
                <table className="invoices-table">
                    <thead>
                        <tr className="size-16">
                            <th>
                                <span className="is-sr-only">
                                    Status
                                </span>
                            </th>
                            <th>ID</th>
                            <th>DATE</th>
                            <th>INVOICE TOTAL</th>
                            <th>
                                <span className="is-sr-only">
                                    Download
                                </span>
                            </th>
                        </tr>
                    </thead>

                    <tbody className="size-18">
                        {invoices.map((invoice, idx) => (
                            <tr key={idx}>
                                <td>
                                    <span className="is-sr-only">
                                        Paid
                                    </span>
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="6.8403" cy="7.78025" rx="6.8403" ry="6.83884" fill="#00DE80" />
                                    </svg>
                                </td>
                                <td>{invoice['InvoiceID']}</td>
                                <td>{format(new Date(invoice['InvoiceDate']), 'LL')}</td>
                                <td>{invoice['InvoiceTotal€']}</td>
                                <td>
                                    <button
                                        className="is-purple is-underlined"
                                        onClick={() => onDownloadInvoiceButtonClick(invoice['InvoiceID'])}
                                    >
                                        Download
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <ContentParagraph>
                No invoices
            </ContentParagraph>
        )}
    </>
);

export default ContractAndInvoicesContent;